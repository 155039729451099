import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import useSettings from "../../hooks/Settings";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import Location from '../../hooks/Location';
import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import Tooltip from "../common/Tooltip";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Auth } from '../../StateManagment/Auth';
import { useFirestore } from "../../firebase/useFirestore";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { ActiveMenu } from '../../StateManagment/ActiveMenuItem';

const LeftSideBar = () => {
    // Context
    const [authO] = useContext(Auth)
    const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu)

    // State
    const [open, setOpen] = useState(false)
    const [delayedOpen, setDelayedOpen] = useState(false);
    const [showImpactStrategy, setShowImpactStrategy] = useState(false)
    const [showMeasumentplan, setShowMeasumentplan] = useState(false)
    const [showPersonas, setShowPersonas] = useState(false)
    const [showCommunication, setShowCommunication] = useState(false)
    const [showFinancing, setShowFinancing] = useState(false)
    const [itemHover, setItemHover] = useState('')

    // Hooks
    const SecColor = useSettings().SecundairyColor
    const client = Location()[1]
    const history = useHistory()
    const logo = useSettings().logo
    const mainColor = useSettings().SecundairyColor
    const inactiveColor = '#616161'

    // Firestore
    const syncs = useFirestore('Synchronisations')

    // useEffect to handle the delayed open state
    useEffect(() => {
        if (open) {
            const timeout = setTimeout(() => {
                setDelayedOpen(true); // Set delayed open to true after 1/10 second
            }, 100); // 1/10 second delay

            return () => clearTimeout(timeout); // Cleanup timeout on unmount or open state change
        } else {
            setDelayedOpen(false); // Set delayed open to false immediately when closing
        }
    }, [open]);

    // Function to render the inner items
    const innerItem = (Icon, url, name, state, setState) => {
        return(
            <div 
            className="sidebar-title-inner-container" 
            onClick={() => {history.push(`/${client}/${url}`); 
            setActive(url); 
            setState(!state);
            setActiveSubItem('');
            }}
            >
                <div
                className='sidebar-title-hover-container'
                onMouseEnter={() => setItemHover({'url': url, 'hover': true})}
                onMouseLeave={() => setItemHover({'url': url, 'hover': false})}
                style={{backgroundColor: itemHover.url === url && itemHover.hover ? 'white' : 'transparent'}}>
                    <Icon 
                        style={{
                            fill: active === url ? mainColor : inactiveColor,
                            }}/>
                    <h3 
                        style={{
                            color: active === url ? mainColor : inactiveColor,
                            fontWeight: active === url ? 'bold' : 600,
                        }}
                        >{name}
                    </h3>
                    {state
                    ? 
                    <KeyboardArrowDownOutlinedIcon className="expand-menu-icon"/> 
                    : 
                    <ChevronRightOutlinedIcon className="expand-menu-icon"/>}
                </div>
            </div>
        )
    }


    // Function to render the inner sub items
    const innerSubItem = (url, name, mainItem) => {
        return(
            <div className="channel-inner-div">
                <div className='activity-meta-title-container'>
                    <p 
                    onClick={() => {history.push(`/${client}/${url}`); 
                    setActive(mainItem); 
                    setActiveSubItem(url)}} 
                    style={{
                        color: activeSubItem === url ? mainColor : inactiveColor, 
                        fontWeight: activeSubItem === url ? 'bold' : 600
                        }}>
                        {name}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div id='left-side-bar-toggle-container' onMouseEnter={() => setOpen(true)}>
            <div id='closed-sidebar-icons-container' style={{display: open ? 'none' : 'flex'}}>
                <HomeOutlinedIcon id='closed-sidebar-home-icon' style={{fill: {SecColor}}}/>
                <DashboardOutlinedIcon style={{fill: active === 'dashboard' ? mainColor : inactiveColor}}/>
                <BarChartOutlinedIcon style={{fill: active === 'impactstrategy' ? mainColor : inactiveColor}}/>
                <BiotechOutlinedIcon style={{fill: active === 'measurementplan' ? mainColor : inactiveColor}}/>
                <GroupsOutlinedIcon style={{fill: active === 'personas' ? mainColor : inactiveColor}}/>
                <AssessmentOutlinedIcon style={{fill: active === 'communication' ? mainColor : inactiveColor}}/>
                <HandshakeOutlinedIcon style={{fill: active === 'financing' ? mainColor : inactiveColor}}/>
            </div>
            <div className="left-sidebar-container" style={{width: open ? '257px' : '0px'}}>
                <div id='leftsidebar-logo-container' >
                    <img 
                    src={logo} 
                    className="top-bar-logo" 
                    alt="logo" 
                    style={{display: delayedOpen ? 'block' : 'none'}} 
                    onClick={() => 
                        {history.push(`/${client}/home`);
                        setActive('');
                        setActiveSubItem('');
                    }}
                    />
                </div>

                <div className="left-side-bar" style={{display: delayedOpen ? 'block' : 'none'}}>
                    <div className="channel-div">

                        {/* Dashboard */}
                        <div 
                        className="sidebar-title-inner-container" 
                        onClick={() => {history.push(`/${client}/dashboard`); 
                        setActive('dashboard');
                        setActiveSubItem('')
                        }}
                        >
                            <div className='sidebar-title-hover-container'
                             onMouseEnter={() => setItemHover({'url': 'dashboard', 'hover': true})}
                             onMouseLeave={() => setItemHover({'url': 'dashboard', 'hover': false})}
                             style={{backgroundColor: itemHover.url === 'dashboard' && itemHover.hover ? 'white' : 'transparent'}}
                            >
                                <DashboardOutlinedIcon 
                                style={{
                                    fill: active === 'dashboard' ? mainColor : inactiveColor,
                                    }}/>
                                <h3 
                                style={{
                                    color: active === 'dashboard' ? mainColor : inactiveColor,
                                    fontWeight: active === 'dashboard' ? 'bold' : 600,
                                    }}
                                    >
                                    Dashboard
                                </h3>
                            </div>
                        </div>

                        {/* Impactstrategy */}
                        {innerItem(BarChartOutlinedIcon, 'impactstrategy', 'Impactstrategie', showImpactStrategy, setShowImpactStrategy)}
                        <div className="channel-items-container" style={{display: showImpactStrategy ? 'block' : 'none'}}>
                            {innerSubItem('organisationcontext', 'Organisatie omschrijving', 'impactstrategy')}
                            {innerSubItem('problemanalysis', 'Maatschappelijk probleem', 'impactstrategy')}
                            {innerSubItem('stakeholders', 'Doelgroepen', 'impactstrategy')}
                            {innerSubItem('Goals', 'Impactdoel', 'impactstrategy')}
                            {innerSubItem('Activities', 'Activiteiten', 'impactstrategy')}
                            {innerSubItem('Outputs', 'Outputs', 'impactstrategy')}
                            {innerSubItem('effects', 'Effecten', 'impactstrategy')}
                            {innerSubItem('theoryofchange', 'Theory of change', 'impactstrategy')}
                        </div>

                        {/* Meaurementplan */}
                        {innerItem(BiotechOutlinedIcon, 'measurementplan', 'Meten', showMeasumentplan, setShowMeasumentplan)}
                        <div className="channel-items-container" style={{display: showMeasumentplan ? 'block' : 'none' }}>
                            {innerSubItem('storytelling', 'Storytelling', 'measurementplan')}
                            {innerSubItem('outputresults', 'Activiteiten', 'measurementplan')}
                            {innerSubItem('research', 'Onderzoeken', 'measurementplan')}
                            {innerSubItem('insights', 'Inzichten', 'measurementplan')}
                        </div>

                        {/* Personas */}
                        {innerItem(GroupsOutlinedIcon, 'personas', 'Deelnemers', showPersonas, setShowPersonas)}
                        <div className="channel-items-container" style={{display: showPersonas ? 'block' : 'none' }}>
                            {innerSubItem('personadashboard', 'Overzicht', 'personas')}
                            {innerSubItem('personas', 'Deelnemers', 'personas')}
                            {innerSubItem('agenda', 'Planning', 'personas')}
                            {innerSubItem('personasettings', 'Instellingen', 'personas')}
                        </div>

                        {/* Communication */}
                        {innerItem(AssessmentOutlinedIcon, 'communication', 'Communiceren', showCommunication, setShowCommunication)}
                        <div className="channel-items-container" style={{display: showCommunication ? 'block' : 'none' }}>
                            {innerSubItem('livereports', 'Live rapportages', 'communication')}
                            {innerSubItem('synchronisations', 'Synchronisaties', 'communication')}
                        </div>
                           
                        {/* Financing */}
                        {innerItem(HandshakeOutlinedIcon, 'financing', 'Financiering', showFinancing, setShowFinancing)}
                        <div className="channel-items-container" style={{display: showFinancing? 'block' : 'none' }}>
                            {innerSubItem('allfunds', 'Alle fondsen', 'fincancing')}
                            {innerSubItem('funds', 'Mijn fondsen', 'financing')}
                        </div>
                    </div>
                </div>
            </div>
            <div id='leftsidebar-toggle-item-container' >
                <div style={{display: open ? 'block' : 'none'}} onClick={() => setOpen(false)}>
                    <Tooltip content='Menu inklappen' >
                        <KeyboardDoubleArrowLeftOutlinedIcon/>
                    </Tooltip>
                </div>
                <div style={{display: open ? 'none' : 'block'}}>
                    <Tooltip content='Menu uitklappen' >
                        <KeyboardDoubleArrowRightOutlinedIcon/>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default LeftSideBar

import { useFirestoreGeneral } from '../../firebase/useFirestore'
import { db } from '../../firebase/config';
import useSettings from "../../hooks/Settings";
import { useState } from 'react';

const UpdateOutputColors = ({activityID}) => {
    // Hooks
    const primaryColor = useSettings().PrimaryColor
    const secundairyColor = useSettings().SecundairyColor;
    const tertiaryColor = useSettings().TertairyColor;

    // Firestore 
    const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activityID ? activityID : '')

    const colorHandler = (e) => {

        const docid = e.target.dataset.docid 
        const color = e.target.value

        console.log(docid)
        console.log(e.target.value)

        db.collection('Outputs')
        .doc(docid)
        .update({
            Color: color
        })
    }


  return (
    <div id="update-output-color-container">
    {outputs &&
        outputs.map((output) => (
            <div key={output.ID}>
                <h3>{output.Title}</h3>
                <div className="color-picker-select-color-container">
                    <input
                        type="radio"
                        name={`color-${output.ID}`} // Unique name for this group
                        data-docid={output.docid}
                        defaultChecked={output.Color === primaryColor}
                        value={primaryColor}
                        onChange={colorHandler}
                    />
                    <div
                        className="color-picker-color-container"
                        style={{ backgroundColor: primaryColor }}
                    ></div>
                </div>
                <div className="color-picker-select-color-container">
                    <input
                        type="radio"
                        name={`color-${output.ID}`} // Same name for all radios in this group
                        data-docid={output.docid}
                        defaultChecked={output.Color === secundairyColor}
                        value={secundairyColor}
                        onChange={colorHandler}
                    />
                    <div
                        className="color-picker-color-container"
                        style={{ backgroundColor: secundairyColor }}
                    ></div>
                </div>
                <div className="color-picker-select-color-container">
                    <input
                        type="radio"
                        name={`color-${output.ID}`} // Same name for all radios in this group
                        data-docid={output.docid}
                        defaultChecked={output.Color === tertiaryColor}
                        value={tertiaryColor}
                        onChange={colorHandler}
                    />
                    <div
                        className="color-picker-color-container"
                        style={{ backgroundColor: tertiaryColor }}
                    ></div>
                </div>
            </div>
        ))}
</div>

  )
}

export default UpdateOutputColors
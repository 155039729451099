import { portfolioFunctions } from '../../firebase/configPortfolio';

const PortfolioEffects = async (portfolioID, effectID) => {
  try {
    const portfolioEffect = portfolioFunctions.httpsCallable('portfolioEffect');

    const data = {
      portfolioID: portfolioID,
      EffectID: effectID,
    };

    const result = await portfolioEffect({ data: data });

    return result.data.title || 'Kan titel niet ophalen';
  } catch (error) {
    console.error('Error fetching portfolio effect:', error);
    throw new Error('Er is iets mis gegaan, neem contact op met Deccos');
  }
};

export default PortfolioEffects;

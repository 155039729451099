import PageHeader from "../../components/layout/PageHeader.jsx";
import { useState } from 'react';
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore.js';
import Location from "../../hooks/Location"

const AddEvent = () => {
    // State
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');

    // Hooks
    const activityId = Location()[3]

    // Firestore 
    const outputs = useFirestoreGeneralOrderBy('Outputs', 'ActivityID', activityId ? activityId : '', 'Position',  'asc');

    console.log(outputs)
  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Gebeurtenis toevoegen'}
                description={`Voeg hier een nieuwe gebeurtenis toe.`}
                AI={false}
            />
            <div className="table-container dashboard-container">
                <h2>Titel</h2>
                <input type="text" placeholder='Shrijf hier de titel van de gebeurtenis' onChange={(e) => setTitle(e.value)} />
            </div>
            <div className="table-container dashboard-container">
                <h2>Datum</h2>
                <input 
                type="date" 
                onChange={(e) => setDate(e.value)} 
                />
            </div>
            <div className="table-container dashboard-container">
                <h2>Outputs</h2>
            </div>
            <div className="table-container dashboard-container">
                <h2>Deelnemers</h2>
            </div>
            <div className="button-container">
                <button className="button">Opslaan</button>
            </div>
        </div>
    </div>
  )
}

export default AddEvent
import { client } from "../../hooks/Client"
import { useFirestoreOrderBy } from "../../firebase/useFirestore"
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import NextStepContainer from "../../components/common/NextStepContainer.jsx";
import Tooltip from "../../components/common/Tooltip";
import { useState, useEffect, useContext } from "react";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import deleteIcon from '../../images/icons/delete-icon.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import { db, timestamp } from "../../firebase/config";
import uuid from 'react-uuid'
import { EffectsSystemMessage, EffectsToolCallContent, EffectsSaveMessage } from "../../hooks/impactAI/Prompts"
import plusButton from '../../images/icons/plus-icon.png'
import SavedNotice from "../../components/common/SavedNotice"
import Modal from 'react-modal';
import EffectDatabase from "../../components/meetstandaard/EffectDatabase";
import EffectDatabaseItemsList from "../../components/meetstandaard/EffectDatabaseItemsList";
import SelectTargetgroup from "../../components/effects/SelectTargetgroup";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import IndicatorsCount from '../../components/Indicators/IndicatorsCount';
import MeetstandardIcon from '../../images/meetstandaard-icon.png'
import MeetstandaardLogo from '../../images/logo-meetstandaard-alt.png'
import firebase from "firebase";
import spinner from '../../images/spinner-ripple.svg'
import PageHeader from "../../components/layout/PageHeader.jsx";
import PageNotice from "../../components/layout/PageNotice";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

const Effects = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State 
    const [hoverIndex, setHoverIndex] = useState(null)
    const [editTerm, setEditTerm] = useState('none')
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedEffects, setSelectedEffects] = useState([])
    const [showAddContainer, setShowAddContainer] = useState(false)

    // Hooks
    ScrollToTop()
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor
    const color = useSettings().TertairyColor
    Modal.setAppElement('#root');
    const effectDatabase = EffectDatabase()
    const modalStyles = {
      content: {
        margin: '5%'
      },
    };

    // Firestore 
    const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc')

    //ImpactAI
    const pageSystemMessage = EffectsSystemMessage()
    const pageToolCallContent = EffectsToolCallContent()
    const pageSaveMessage = EffectsSaveMessage()
    const pageStartMessage = `Welkom bij de effecten, ${auth?.ForName}. Hier ga je de maatschappelijke effecten benoemen die je met je activiteiten wilt bereiken.
    Kan ik een voorstel doen?`
    const pageExampleMessages = []
    const pageCollection = 'OutputEffects'
    const pageField = 'Effect'
    const pageParentId = ''
    const pageType = 'effect'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth])

    // Update the title
    const titleHandler = (e) => {
        const effect = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('OutputEffects')
        .doc(docid)
        .update({
            Effect: effect
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Delete the effect
    const deleteEffect = (e) => {

        const docid = e.target.dataset.docid
        const id = e.target.dataset.id

        db.collection('OutputEffects')
        .doc(docid)
        .delete()
        .then(() => {
            removeIndicators(id)
        })
    }

    // Remove the indicators
    const removeIndicators = (id) => {
        db.collection('QuestionnaireFields')
        .where('EffectId', 'array-contains', id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                db.collection('QuestionnaireFields')
                .doc(doc.id)
                .update({
                    EffectId: firebase.firestore.FieldValue.arrayRemove(id)
                })
            })
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Add a new effect
    const addEffect = () => {

        db.collection('OutputEffects')
        .add({
            Effect: '',
            Position: effects.length + 1,
            ID: uuid(),
            Compagny: client,
            Term: 'long',
            CompagnyID: client,
            Timestamp: timestamp,
            type: 'manual'
        })
        .then(() => {
            setSaved('flex')
        })
    }

    // Update the term
    const termHandler = (e) => {
        const term = e.target.options[e.target.selectedIndex].value
        const docid = e.target.dataset.docid

        db.collection('OutputEffects')
        .doc(docid)
        .update({
            Term: term
        })
        .then(() => {
            setSaved('flex')
            setEditTerm('none')
        })

    }

    // Edit the term
    const editTermHandler = () => {
        editTerm === 'none' ? setEditTerm('block') : setEditTerm('none')
    }

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Save selected effects
    const saveSelectedEffects = () => {

        selectedEffects.forEach((effect) => {

            const fieldId = uuid()
            const effectId = uuid()

            db.collection('OutputEffects')
            .add({
                Effect: effect.effect,
                MainEffect: effect.category,
                Position: effects.length + 1,
                ID: effectId,
                MSIId: effect.id,
                Compagny: client,
                CompagnyID: client,
                Timestamp: timestamp,
                Standard: 'true',
            })
            .then(() => {
                db.collection('QuestionnaireFields')
                    .doc()
                    .set({
                        Compagny: client,
                        CompagnyID: client,
                        ID: fieldId,
                        Timestamp: timestamp,
                        QuestionnaireID: '',
                        Type: 'matrix-one',
                        Question: 'In welke mate ben je het eens met de volgende stellingen?',
                        Explainer: '',
                        ReachStart: 1,
                        ReachStartLable: '',
                        ReachEnd: 5,
                        ReachEndLabel: '',
                        Multiple: [],
                        SectionID: '',
                        MSIId: effect.id,
                        EffectId:  [effectId],
                        Indicator: 'true',
                        LiveReportHidden: false,
                        Standard: 'true',
                    })

            })
            .then(() => {
                effect.questions.forEach((question, index) => {
                    db.collection('MatrixQuestionRows')
                    .doc()
                    .set({
                        Title: question.name,
                        Field: fieldId,
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        Position: index + 1,
                        ID: uuid(),
                        MSIId: question.id,
                        Color: color,
                    })
                })
            })
            .then(() => {
                for (let i = 0; i < 5; i++) {
                    db.collection('MatrixQuestionColumns')
                    .doc()
                    .set({
                        Title: i + 1,
                        Field: fieldId,
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        Position: i + 1,
                        ID: uuid(),
                        Color: color,
                    });
                }
            })
        })

        setModalOpen(false)

    }

    const effectTerm = (term) => {
        if (term === 'short') {
            return 'Korte termijn'
        } else if (term === 'long') {
            return 'Lange termijn'
        } else {
            return 'Geen termijn toegevoegd'
        }
    }

    // Get the standard effect title
    const standardEffectTitle = (id) => {
        for (const category of effectDatabase) {
            const effect = category.effects.find(effect => effect.id === id);
            if (effect) return effect.name;
        }
        return ''; // Return an empty string if the effect with the given ID isn't found
    };

    // Check if effect is paired with a portfolio account
    const effectIsPaired = async (id) => {
        
        const querySnapshot = await db.collection('Synchronisations')
        .where('CompagnyID', '==', client)
        .where('ProjectEffect', '==', id)
        .get()

        console.log(querySnapshot.size)

        if (querySnapshot.size === 0) {
            return false
        } else {
            return true
        }
    }

  return (
    <div className="main">
    <div className="main-container">
        <PageHeader 
            heading={'Effecten'}
            description={`Hier ga je de maatschappelijke effecten benoemen die je met je activiteiten wilt bereiken.`}
            AI={true}
        />
        <PageNotice 
            text={<span dangerouslySetInnerHTML={{ __html: 'Effecten bestaan uit minimaal 3 stappen: <ol><li>De titel</li><li>De indicatoren</li><li>De doelgroep</li></ol> Om door te kunnen moeten alle stappen zijn ingevuld.' }} />}
        />
        <div className="table-container dashboard-container">
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>EFFECT</th>
                        <th style={{backgroundColor: secundairyColor}}>INDICATOREN</th>
                        <th style={{backgroundColor: secundairyColor}}>DOELGROEP</th>
                        <th style={{backgroundColor: secundairyColor}}>TERMIJN</th> 
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                </thead>    
                {effects && effects.map((effect, index) => (
                    <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={effect}
                        input={
                            effect.Standard === 'true' ?
                                <Tooltip content={'Gestandaardiseerde effecten kunnen niet worden aangepast'} width='80%' right='-220px' top='-55px'>
                                    <p className='table-p'>{standardEffectTitle(effect.MSIId)}</p>
                                </Tooltip>
                            :
                                <Tooltip content={effect.Effect} width='80%' right='-220px' top='-35px'>
                                    <input type="text" data-docid={effect.docid} defaultValue={effect.Effect} placeholder='Effect' onChange={titleHandler}/>
                                </Tooltip>
                        }
                        parentIndex={index}
                        collection={'OutputEffects'}
                        itemArray={effects}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={effect.Effect}
                        setPlaceholderIndex={setPlaceholderIndex}
                        >
                        <td>
                            <div id='indicator-effect-icons-container'>
                                <Tooltip content={'Indicatoren toevoegen'} top='-60px' width={'30px'}>
                                    <img  src={plusButton} alt="" className='table-delete-icon' onClick={() => history.push(`/${client}/addindicators/${effect.ID}`)}/>
                                </Tooltip>
                                <Tooltip content={'Aantal indicatoren'} top='-60px' width='30px'>
                                    <p style={{backgroundColor: secundairyColor}}><IndicatorsCount effectId={effect.ID} /></p>
                                </Tooltip>
                            </div>
                        </td>

                        <td>
                            <SelectTargetgroup effect={effect} />
                        </td>
                        <td>
                            <div className="table-edit-container">
                                <div id='edit-term-container'>
                                    <select name="" id="" data-docid={effect.docid} onChange={termHandler} style={{display: editTerm}}>
                                        <option value="">-- Selecteer termijn --</option>
                                        <option value="short">Korte termijn</option>
                                        <option value="long">Lange termijn</option>
                                    </select>
                                </div>
                                <p style={{display: editTerm === 'none' ? 'flex' : 'none'}}>{effectTerm(effect.Term)}</p>
                                <Tooltip content={'Termijn aanpassen'} top='-60px'>
                                    <CreateOutlinedIcon onClick={editTermHandler}/>
                                </Tooltip>
                            </div>
                        </td>
                        <td>
                            <div className="table-delete-container">
                                {/* Show MSI icon if effect is standardised */}
                                {effect.Standard === 'true' ? (
                                    <Tooltip content={'Let op: dit is een gestandaardiseerd effect'} top='-80px' left='-50px'>
                                        <img 
                                            className='table-delete-icon' 
                                            data-id={effect.ID} 
                                            data-docid={effect.docid} 
                                            src={MeetstandardIcon} 
                                            onClick={deleteEffect} 
                                            alt=""
                                        />
                                    </Tooltip>
                                ) : null}
                            
                                {/* Show paired icon only if effect is paired AND NOT an MSI effect */}
                                {effectIsPaired(effect.ID) && effect.Standard !== 'true' ? (
                                    <Tooltip content={'Dit effect is gekoppeld aan een portfolio account'} top='-100px' left='-50px'>
                                        <LinkOutlinedIcon className='table-delete-icon' />
                                    </Tooltip>
                                ) : null}
                            
                                {/* Show delete icon only if effect is NOT paired and NOT standardised */}
                                {!effectIsPaired(effect.ID) && effect.Standard !== 'true' ? (
                                    <Tooltip content={'Effect verwijderen'} top='-80px' left='-50px'>
                                        <img 
                                            className='table-delete-icon' 
                                            data-id={effect.ID} 
                                            data-docid={effect.docid} 
                                            onClick={deleteEffect} 
                                            src={deleteIcon} 
                                            alt=""
                                        />
                                    </Tooltip>
                                ) : null}
                            </div>
                    
                        
                        </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
            <AddItemTableRow toolTipContent={'Effect toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
            <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                <div className="add-options-container-item-container" onClick={addEffect}>
                    <img src={plusButton} alt="" />
                    <p>Eigen effect toevoegen</p>
                </div>
                <div className="add-options-container-item-container" onClick={() => setModalOpen(true)}>
                    <img src={MeetstandardIcon} alt="" />
                    <p>Effect uit Meetstandaard Social Impact toevoegen</p>
                </div>
            </div>
        </div>
        <div className="impactstrategy-saved-and-nextstep-container">
            <SavedNotice />
            <NextStepContainer url={'theoryofchange'} mainItem={'impactstrategy'}/>
        </div>
        <Breadcrumb
        section={'Impactstrategie'}
        previousPage={'Outputs'}
        previousPageUrl={'Outputs'}
        nextPage={'Theory Of Change'}
        nextPageUrl={'theoryofchange'}
        counter={7}
        totalSteps={8}
        />
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={modalOpen}
        style={modalStyles}
        contentLabel="Effect selecteren"
        >
            <div>
                <div className="page-header">
                    <img src={MeetstandaardLogo} alt="" />
                    <h1>Meetstandaard Social Impact</h1>
                </div>
                <div>
                    {effectDatabase 
                    
                    ? 
                    
                        effectDatabase.map((category, index) => (
                            <EffectDatabaseItemsList category={category} index={index} setSelectedEffects={setSelectedEffects} secundairyColor={secundairyColor} />
                        ))
                
                    :
                        <img src={spinner} alt="" />
                    }
                </div> 
                <div id='modal-button-container'>
                    <button id='modal-cancel-button'onClick={() => setModalOpen(false)}>Annuleren</button>
                    <button id='modal-save-button'  onClick={saveSelectedEffects}>Opslaan</button>
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default Effects
import { useState, useEffect } from 'react';
import { useFirestoreGeneral, useFirestoreGeneralTwo, useFirestoreOrderBy } from '../../firebase/useFirestore';
import { db, timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext } from "react";
import useSettings from "../../hooks/Settings";
import Tooltip from '../../components/common/Tooltip'
import deleteIcon from '../../images/icons/delete-icon.png'
import DraggableTableItems from '../../components/common/DraggableTableItems'
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import ActivityMeta from '../../components/activities/ActivityMeta.jsx';
import React from 'react';
import SelectOutput from '../../components/outputs/SelectOutput.jsx';

const Activities = ({personaNavigation, personaId}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null)
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState('')

    // Hooks
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const secundairyColor = useSettings().SecundairyColor


    // Firestore
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')
    const personaActivities = useFirestoreGeneral('PersonaActivities', 'PersonaID', personaId ? personaId : '')
    const personaActivitiesMain = useFirestoreGeneralTwo('PersonaActivities', 'PersonaID', personaId ? personaId : '', 'Type', '==', 'main')

    // Main activity handler
    const mainActivityHandler = (e) => {
        const activityId = e.target.value
        setSelectedActivity(activityId)
    }

    // Add main activity
    const addMainActivity = () => {

        console.log(selectedActivity)

        db.collection('PersonaActivities')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            ActivityID: selectedActivity,
            Type: 'main',
            Position: personaActivities.length + 1,
            Timestamp: timestamp
        })
        .then(() => {
            setSaved('flex')
            setShowAddContainer(!showAddContainer)
        })  
    }

    // Delete activity
    const deleteActivity = (e) => {
        const docid = e.target.dataset.docid
        db.collection('PersonaActivities').doc(docid).delete()
    }

    // Output handler
    const outputHandler = (e) => {
        const docid = e.target.dataset.docid
        const outputId = e.target.options[e.target.selectedIndex].value
        db.collection('PersonaActivities').doc(docid).update({
            OutputId: outputId
        })
    }

  return (
    <div style={{display: personaNavigation === 'activities' ? 'block' : 'none'}}>
        <h2>Activiteiten</h2>
        <div  className="persona-option-container">     
            <h3>Hoofdactiviteiten</h3>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>HOOFDACTIVITEIT</th>
                        <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                    </tr>
                </thead>
                <tbody>
                        {personaActivities && personaActivities.map((activity, index) => (
                        <React.Fragment key={activity.docid}>  
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td colSpan="3" style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems
                                item={activity}  
                                input={
                                    <ActivityMeta id={activity.ActivityID} />
                                }
                                parentIndex={index}
                                collection={'PersonaActivities'}
                                itemArray={personaActivities}
                                setHoverIndex={setHoverIndex}
                                hoverIndex={hoverIndex}
                                title={activity.Timestamp.toDate().toLocaleDateString("nl-NL", options)}
                                setPlaceholderIndex={setPlaceholderIndex}
                            >
                                <td>
                                    {console.log(activity)}
                                    <select name="" id="" defaultValue={activity.OutputId} data-docid={activity.docid} onChange={outputHandler}>
                                        <option value="">-- Selecteer output --</option>
                                        <SelectOutput activityId={activity.ActivityID}/>
                                    </select>
                                </td>
                                <td>
                                        <Tooltip content={'Activiteit verwijderen'} top='-60px' width={'30px'}>
                                            <img className='table-delete-icon' data-docid={activity.docid} onClick={deleteActivity} src={deleteIcon} alt="" />
                                        </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            <AddItemTableRow toolTipContent={'Activiteit toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
            <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                <select name="" id="" onChange={mainActivityHandler}>
                    <option value="">-- Selecteer hoofdactiviteit --</option>
                    {activities && activities.map(activity => (
                        <option key={activity.ID} value={activity.ID}>{activity.Activity}</option>
                    ))}
                </select>
                <button className='button-simple' onClick={addMainActivity}>Opslaan</button>
            </div>
        </div>
    </div>
  )
}

export default Activities
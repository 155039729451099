import { portfolioFunctions } from '../../firebase/configPortfolio';

const PortfolioOutputs = async (portfolioID, outputID) => {
  try {
    const portfolioOutput = portfolioFunctions.httpsCallable('portfolioOutput');

    const data = {
      portfolioID: portfolioID,
      outputID: outputID,
    };

    const result = await portfolioOutput({ data: data });

    return result.data.title || 'Kan titel niet ophalen';
  } catch (error) {
    console.error('Error fetching portfolio output:', error);
    throw error; 
  }
};

export default PortfolioOutputs;
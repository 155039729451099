    import {
        ComposedChart,
        Line,
        XAxis,
        YAxis,
        CartesianGrid,
        Tooltip,
        Legend,
        Scatter,
        ResponsiveContainer,
    } from 'recharts';
    import useSettings from "../../hooks/Settings";
    import { useState, useEffect } from 'react';
    import { useFirestoreQuestionnairesResponses } from '../../firebase/useFirestore'

const ScaleMomentScatterChart = ({moment, field}) => {
    const [data, setData] = useState([{index: 0, score: 0}])
    const [currentScore, setCurrentScore] = useState(0)
    const [currentDate, setCurrentDate] = useState(0)

    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
    const tertiaryColor = useSettings().TertairyColor
    const primaryColor = useSettings().PrimaryColor

    const results = useFirestoreQuestionnairesResponses(field, moment)

    const createDataArray = () => {

        const data = []
        results && results.map((result) => {

            const score = Number(result.Input)
            const date = Number(result.Timestamp.toDate())

            const object = {
                index: date,
                score: score,
                id: result.ID,
                date: result.Timestamp.toDate().toLocaleDateString("nl-NL", options)
            }
            data.push(object)
        })

        setData(data)
    }

    useEffect(() => {
        createDataArray()
    }, [results])

    //   const lineData = [
    //     { index: data[0].index, score: data[0].score, redLine: data[0].score },
    //     { index: data[data.length - 1].index, score: data[data.length - 1].score, redLine: data[0].score },
    // ];

    const pointMeta = (e) => {
        console.log(e)
        setCurrentScore(e.payload.score)
        setCurrentDate(e.payload.date)
    }

  return (
    <div>
        <ComposedChart
            width={500}
            height={400}
            data={data && data}
            margin={{
            top: 20,
            right: 80,
            bottom: 20,
            left: 20,
            }}
        >
            {/* <Tooltip onClick={tooltip}/> */}
            <Legend/>
            <XAxis dataKey="index" style={{visibility: 'hidden'}} type="date" label={{ value: 'datum', position: 'insideBottomRight', offset: 0 }} />
            <YAxis  type="number" label={{ value: 'Score', angle: -90, position: 'insideLeft' }} />
            <Scatter name="score" dataKey="score" fill={tertiaryColor} onClick={pointMeta} style={{cursor: 'pointer'}} />
            {/* <Line dataKey="redLine" stroke={primaryColor} dot={false} activeDot={false} legendType="none" /> */}
        </ComposedChart>
        <div className='selected-scatter-point-outer-container'>
            <p><b>Geselecteerd datapunt</b></p>
            <div className='selected-scatter-point-container'>
                <p>Score: {currentScore}</p>
                <p>Datum: {currentDate}</p>
            </div>
        </div>
       
    </div>
    
  )
}

export default ScaleMomentScatterChart
import React, { useEffect, useState } from "react";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { useFirestore } from "../../firebase/useFirestore";
import PortfolioMeta from "../../components/syncronisations/PortfolioMeta";
import PortfolioOutputs from "../../components/syncronisations/PortfolioOutputs";
import PortfolioEffects from "../../components/syncronisations/PortfolioEffects";
import useSettings from "../../hooks/Settings";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const Synchronisations = () => {
  // State
  const [outputTitles, setOutputTitles] = useState({});
  const [effectTitles, setEffectTitles] = useState({});

  // Hooks
  const secundairyColor = useSettings().SecundairyColor;
  const history = useHistory();

  // Firestore
  const syncs = useFirestore("Synchronisations");

  // Fetch the output title for a specific sync item
  const fetchOutputTitle = async (id, portfolioID, syncItem) => {
    try {
      const title = await PortfolioOutputs(portfolioID, syncItem);
      setOutputTitles((prevTitles) => ({
        ...prevTitles,
        [id]: title || "kan titel niet ophalen",
      }));
    } catch (error) {
      console.error("Error fetching portfolio output:", error);
      setOutputTitles((prevTitles) => ({
        ...prevTitles,
        [id]: "Error fetching output",
      }));
    }
  };

  // Fetch the effect title for a specific sync item
  const fetchEffectTitle = async (id, portfolioID, syncItem) => {
    try {
      const title = await PortfolioEffects(portfolioID, syncItem);
      setEffectTitles((prevTitles) => ({
        ...prevTitles,
        [id]: title || "Kan titel niet ophalen",
      }));
    } catch (error) {
      console.error("Error fetching portfolio effect:", error);
      setEffectTitles((prevTitles) => ({
        ...prevTitles,
        [id]: "Error fetching effect",
      }));
    }
  };

  // Fetch titles whenever syncs change
  useEffect(() => {
    if (syncs) {
      syncs.forEach((sync) => {
        if (sync.Type === "output" && !outputTitles[sync.ID]) {
          fetchOutputTitle(sync.ID, sync.PortfolioID, sync.SyncItem);
        } else if (sync.Type === "effect" && !effectTitles[sync.ID]) {
          fetchEffectTitle(sync.ID, sync.PortfolioID, sync.SyncItem);
        }
      });
    }
  }, [syncs]);

  const status = (statusCode) => {
    switch (statusCode) {
      case "requested":
        return { text: "Verzoek", color: "#FFA500" };
      case "accepted":
        return { text: "Geaccepteerd", color: "#008000" };
      case "declined":
        return { text: "Geweigerd", color: "#FF0000" };
      case "deleted":
        return { text: "Verwijderd", color: "#000000" };
      default:
        return { text: "Onbekende status", color: "#000000" };
    }
  };

  const syncStatus = (statusCode) => {
    switch (statusCode) {
      case "requested":
        return { text: "Inactief", color: "#FFA500" };
      case "accepted":
        return { text: "Actief", color: "#008000" };
      case "declined":
        return { text: "Inactief", color: "#FF0000" };
      case "deleted":
        return { text: "Inactief", color: "#FF0000" };
      default:
        return { text: "Onbekende status", color: "#000000" };
    }
  };

  const type = (type) => {
    switch (type) {
      case "output":
        return "Output";
      case "effect":
        return "Effect";
      default:
        return "Onbekend";
    }
  };

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <SyncOutlinedIcon />
          <h1>Synchronisations</h1>
        </div>
        <div className="dashboard-container">
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: secundairyColor }}>ORGANIATIE</th>
                <th style={{ backgroundColor: secundairyColor }}>TYPE</th>
                <th style={{ backgroundColor: secundairyColor }}>NAAM</th>
                <th style={{ backgroundColor: secundairyColor }}>STATUS</th>
                <th style={{ backgroundColor: secundairyColor }}>
                  SYNCHRONISATIE
                </th>
                <th style={{ backgroundColor: secundairyColor }}>AANPASSEN</th>
              </tr>
            </thead>
            <tbody>
              {syncs &&
                syncs.map((sync) => (
                  <tr key={sync.ID}>
                    <td>
                      <PortfolioMeta portfolioID={sync.PortfolioID} syncs={syncs} />
                    </td>
                    <td>{type(sync.Type)}</td>
                    <td>
                      {sync.Type === "output" ? (
                        <p>{outputTitles[sync.ID] || "Loading..."}</p>
                      ) : sync.Type === "effect" ? (
                        <p>{effectTitles[sync.ID] || "Loading..."}</p>
                      ) : null}
                    </td>
                    <td>
                      <p style={{ color: status(sync.Status).color }}>
                        {status(sync.Status).text}
                      </p>
                    </td>
                    <td>{syncStatus(sync.Status).text}</td>
                    <td>
                      <EditOutlinedIcon
                        className="table-delete-icon"
                        onClick={() =>
                          history.push(
                            `/${client}/synchronisationbuilder/${sync.ID}`
                          )
                        }
                        alt=""
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Synchronisations;


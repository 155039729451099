import { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import { useFirestoreGeneral } from '../../firebase/useFirestore'

const MultipleMultiLineGraph = ({field, researchID}) => {
    const [data, setData] = useState(null)
   
    const options =  useFirestoreGeneral('MultipleQuestionOptions', 'Field', field)

    // Query input results
    const queryResults = async (option, moment) => {

        const querySnapshot = await db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('MomentID', '==', moment)
        .where('FieldID', '==', field)
        .where('Input', '==', option)
        .get()

        const array = []

        querySnapshot.docs.map(async (doc) => {
            array.push(doc.data())
        })

        return array.length

    }

    // Get measure moments
    const getMoments = async () => {

        const momentSnapshot = 
        await db.collection("MeasureMoments")
        .where('CompagnyID', '==', client)
        .where('ResearchID', '==', researchID)
        .orderBy("Position", "asc")
        .get();
      
        const momentPromise = momentSnapshot.docs.map(async (moment) => {
            const momentData = {
                name: moment.data().Title,
            }

            const momentID = moment.data().ID

            const querySnapshot = await db.collection('MultipleQuestionOptions')
            .where('CompagnyID', '==', client)
            .where('Field', '==', field)
            .get()
    
            querySnapshot.docs.map(async input => {
                momentData[input.data().Option] = await queryResults(input.data().Option, momentID)
            })

            return momentData
        })
      
          const moments = await Promise.all(momentPromise)
      
          return moments
      }

      useEffect(() => {
        getMoments().then((moments) => {
            setTimeout(() => {
                options && setData(moments)
            }, 1000)
        })
        }, [field, researchID, options])

    return (

    <LineChart
    width={500}
    height={300}
    data={data && data}
    margin={{
    top: 5,
    right: 30,
    left: 20,
    bottom: 5,
    }}
    >
        
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        {options && options.map((option, index) => (
           <Line key={`line-${index}`} type="monotone" dataKey={option.Option} stroke={option.Color} strokeWidth='2'/>
        ))}
    </LineChart>
    )
}

export default MultipleMultiLineGraph
import { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import { useFirestoreAnalysisCategories } from '../../firebase/useFirestore'
import useSettings from "../../hooks/Settings";

const DevelopmentMulitlinegraph = ({field, researchID}) => {

const [data, setData] = useState(null)
const [colors, setColors] = useState([])

// Set colors
const primaryColor = useSettings().PrimaryColor
const secondaryColor = useSettings().SecundairyColor
const tertiaryColor = useSettings().TertairyColor

useEffect(() => {
  setColors([primaryColor, secondaryColor, tertiaryColor, primaryColor, secondaryColor, tertiaryColor])
},[primaryColor, secondaryColor, tertiaryColor])

const categories = useFirestoreAnalysisCategories(field && field)

const getwords = async (item, moment) => {
  
  let words = 0
  
  await db.collection('AnalysisWords')
      .where('CompagnyID', '==', client ? client : '123')
      .where('FieldID', '==', field ? field : '123')
      .where('MomentID', '==', moment ? moment : '123')
      .where('CategoryID', '==', item ? item : '123')
      .get()
      .then((querySnapshot) => {
         words = querySnapshot.size

      })

  return words
}

const getMoments = async () => {

  const momentSnapshot = 
  await db.collection("MeasureMoments")
  .where('CompagnyID', '==', client ? client : '123')
  .where('ResearchID', '==', researchID ? researchID : '123')
  .orderBy("Position", "asc")
  .get();

  const momentPromise = momentSnapshot.docs.map(async (moment) => {
      const momentData = {
          name: moment.data().Title,
          categories: []
      }

      const categoriesSnapshot = 
      await db.collection('AnalysisCategories')
      .where('CompagnyID', '==', client ? client : '123')
      .where('FieldID', '==', field ? field : '123')
      .get()

      const categorieArray =  []

      categoriesSnapshot.docs.map(async (cat) => {
        momentData[cat.data().Categorie] = await getwords(cat.data().ID, moment.data().ID)
        momentData.categories.push(cat.data().Categorie)
        momentData.color = cat.data().Color
      })

      momentData.categories = categorieArray

      return momentData
    })

    const moments = await Promise.all(momentPromise)

    return moments
}

    useEffect(() => {   
        getMoments().then((data) => {
            setTimeout(() => {
              setData(data)
            } , 2000)
        })
    },[field, researchID])

    return (
      <LineChart
        width={500}
        height={300}
        data={data && data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
          {categories && categories.map((cat, index) => (
            <Line key={`line-${index}`} type="monotone" dataKey={cat.Categorie} stroke={cat.Color} strokeWidth='2'/>
          ))}
      </LineChart>
    );
   
}

export default DevelopmentMulitlinegraph

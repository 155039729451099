import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useSettings from "../../hooks/Settings";

const SimpleLineChart = ({ data, uniqueRowTitles, growthDataForRows }) => {
  const [hoveredLine, setHoveredLine] = useState(uniqueRowTitles ? uniqueRowTitles[0] : null);

  const primaryColor = useSettings().PrimaryColor;
  const secondaryColor = useSettings().SecundairyColor;
  const tertiaryColor = useSettings().TertairyColor;


  const CustomizedLegend = ({ payload }) => {
    return (
      <ul>
        {payload.map((entry, index) => {
          const rowTitle = entry.dataKey;
          const growthArray = growthDataForRows[rowTitle]; // Get the growth array for this rowTitle
          const lastGrowth = growthArray ? growthArray[growthArray.length - 1] : null; // Get the most recent growth value
  
          const growthLabel = lastGrowth !== null
            ? ` (${lastGrowth > 0 ? '+' : ''}${lastGrowth}%)`
            : ''; // Append '+' for positive growth
  
          return (
            <li
              key={`item-${index}`}
              onMouseEnter={() => setHoveredLine(rowTitle)}
              onMouseLeave={() => setHoveredLine(null)}
              style={{
                cursor: 'pointer',
                fontWeight: hoveredLine === rowTitle ? 'bold' : 'normal',
              }}
            >
              {rowTitle}{growthLabel} {/* Display growth with "+" or "-" next to the row title */}
            </li>
          );
        })}
      </ul>
    );
  };
  

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        data={data ? data : []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend content={<CustomizedLegend />} verticalAlign="bottom" />
        {uniqueRowTitles && uniqueRowTitles.map((rowTitle, index) => (
          <Line
            key={`line-${index}`}
            type="monotone"
            dataKey={rowTitle}
            stroke={primaryColor || "#47acc3"}
            strokeWidth='2'
            activeDot={{ r: 8 }}
            strokeOpacity={hoveredLine && hoveredLine !== rowTitle ? 0.1 : 1}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineChart;
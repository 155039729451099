import Location from "../../hooks/Location"
import { useState, useEffect, useContext } from "react"
import { useFirestore, useFirestoreGeneral, useFirestoreGeneralArrayContains, useFirestoreGeneralOrderBy } from "../../firebase/useFirestore"
import deleteIcon from '../../images/icons/delete-icon.png'
import AddItemTableRow from '../../components/common/AddItemTableRow'
import Tooltip from '../../components/common/Tooltip'
import { db } from "../../firebase/config"
import { SavedIcon } from "../../StateManagment/SavedIcon"
import useSettings from "../../hooks/Settings";
import personaGoalProgress from "../../components/AI/PersonaGoalProgress"

const Evaluations = ({ personaNavigation, item, courseStartDate, courseEndDate }) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [title, setTitle] = useState('')
    const [docid, setDocid] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState('')
    const [personaName, setPersonaName] = useState('')
    const [suggestion, setSuggestion] = useState('')
    
    // Hooks
    const personaId = Location()[3]
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', personaId ? personaId : '')
    const reports = useFirestoreGeneral('PersonaReports', 'PersonaID', personaId ? personaId : '')
    const personas = useFirestoreGeneral('Personas', 'ID', personaId ? personaId : '')
    const methodes = useFirestore('PersonaMethodes')
    const activities = useFirestore('Activities')
    const results = useFirestoreGeneralOrderBy(
        'QuestionnairesResponses', 
        'Persona', personaId ? personaId : '', 
        'Timestamp', 'asc' )

    // Set persona name in state
    useEffect(() => {
        personas && personas.forEach(item => {
            setPersonaName(item.Name)
        })
    }, [personas])

    // Goals data
    const goalData = async() => {

        const data = [];

        if (goals && goals.length > 0) {
            for (const goal of goals) {
            data.push({
                ID: goal.ID,
                Goal: goal.Goal,
                Description: goal.Description
            })
        }
    }

        return data;

    }

    // Research results with async/await
   const researchResults = async () => {
        const processedResults = [];

        if (results && results.length > 0) {
            for (const result of results) {
                const object = {
                    question: result.RowTitle,
                    answer: result.Input
                };
                processedResults.push(object);
            }
        }

        return processedResults;
    }

    // Methodes
    const methodesList = async () => {
        const list = [];

        if (methodes && methodes.length > 0) {
            for (const methode of methodes) {
                const object = {
                    title: methode.Title,
                    description: methode.Description
                };
                list.push(object);
            }
        }

        return list;
    }

    // Methodes
    const activitiesList = async () => {
        const list = [];

        if (activities && activities.length > 0) {
            for (const activity of activities) {
                list.push(activity.Activity);
            }
        }

        return list;
    }

    // Add goal progress
    const createGoalProgress = async () => {
        setLoading('Rapportages analyseren...');
        setLoading('Onderzoeksresulaten analyseren...');

        // Wait for research results before constructing the prompt
        const researchData = await researchResults();
        const goals = await goalData();
        const methodes = await methodesList();
        const activities = await activitiesList();

        const prompt = [
            { "role": "system", "content": "Je bent een expert op het gebied van analyseren van rapportages en onderzoeksresulaten." },
            { "role": "user", "content": `
                Wij willen graag dat je een analyse maakt van de voortgang van de doelen van ${personaName}.
                Maak een analyse van de voortgang voor deze doelen: ${JSON.stringify(goals)}. 

                Analyseer de rapportages en onderzoeksresultaten en geef aan wat de voortgang is op de doelen. Geef aan wat er goed gaat en wat er beter kan.

                Dit zijn de rapportages die je kunt analyseren:
                ${reports.map(report => report.Body).join(', ')}

                Dit zijn de onderzoeksresultaten die je kunt analyseren:
                ${JSON.stringify(researchData)}

                Maak bij je aanvebelingen gebruik van de volgende methodes die wij gebruiken bij het begeleiden van onze deelnemers:
                ${JSON.stringify(methodes)}.

                Onze organisatie biedt de volgende activiteiten aan:
                ${JSON.stringify(activities)}.
                Houd hier rekening mee bij het geven van je aanbevelingen.

                Je respons moet als volgt gestruktureerd zijn:

                1. Doel:
                2. Voortgang:
                3. Aanbevelingen:
            ` }
        ];

        personaGoalProgress(prompt, setSuggestion, setLoading);
    }

    // Create reports analysis
    const createReportsAnalasis = async () => {
        setLoading('Rapportages analyseren...');
        setLoading('Onderzoeksresulaten analyseren...');

        // Wait for research results before constructing the prompt
        const researchData = await researchResults();
        const goals = await goalData();
        const methodes = await methodesList();
        const activities = await activitiesList();

        const prompt = [
            { "role": "system", "content": "Je bent een expert op het gebied van analyseren van rapportages en onderzoeksresulaten." },
            { "role": "user", "content": `
                Wij willen graag dat je een analyse maakt van de rapportages van ${personaName}.

                Analyseer de rapportages en geef aan wat de voortgang is op de doelen. Geef aan wat er goed gaat en wat er beter kan.

                Dit zijn de rapportages die je kunt analyseren:
                ${reports.map(report => report.Body).join(', ')}

                Dit zijn de doelen die we willen bereiken:
                ${JSON.stringify(goals)}

                Maak bij je aanvebelingen gebruik van de volgende methodes die wij gebruiken bij het begeleiden van onze deelnemers:
                ${JSON.stringify(methodes)}.

                Onze organisatie biedt de volgende activiteiten aan:
                ${JSON.stringify(activities)}.

                Houd hier rekening mee bij het geven van je aanbevelingen.

                Je respons moet als volgt gestruktureerd zijn:

                1. Doel:
                2. Aanbevelingen:
            ` }
        ];

        personaGoalProgress(prompt, setSuggestion, setLoading);
    }

    console.log(suggestion.content)

  return (
    <div style={{ display: personaNavigation === 'evaluations' ? 'block' : 'none' }}>
      <h2>Inzichten</h2>

        <p onClick={createReportsAnalasis}>Samenvatting van dagrapportages</p>
        <p></p>
        <p onClick={createGoalProgress}>Voortgangsrapportage</p>
        <p>{loading}</p>
        <p>{suggestion.content}</p>
    </div>
  )
}

export default Evaluations
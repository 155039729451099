import { useFirestoreGeneral } from '../../firebase/useFirestore'

const PersonaMeta = ({personaId}) => {

    console.log(personaId)

    const personas = useFirestoreGeneral('Personas', 'ID', personaId ? personaId : '')

  return (
    <>
        {personas && personas.map(persona => (
            <div key={persona.ID} >
                {persona.Name}
            </div>
        ))}
    </>
  )
}

export default PersonaMeta
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useFirestoreGeneral } from '../../firebase/useFirestore';
import PortfolioMeta from '../../components/syncronisations/PortfolioMeta';
import PortfolioOutputs from '../../components/syncronisations/PortfolioOutputs';
import PortfolioEffects from '../../components/syncronisations/PortfolioEffects';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client";
import { useState, useEffect } from 'react';

const Notifications = () => {
  // State
  const [outputTitles, setOutputTitles] = useState({});
  const [effectTitles, setEffectTitles] = useState({});

  // Hooks
  const history = useHistory()

  // Firestore
  const syncs = useFirestoreGeneral('Synchronisations', 'Status', 'requested')

  // Fetch the output title for a specific sync item
  const fetchOutputTitle = async (id, portfolioID, syncItem) => {
    try {
      const title = await PortfolioOutputs(portfolioID, syncItem);
      setOutputTitles((prevTitles) => ({
        ...prevTitles,
        [id]: title || "kan titel niet ophalen",
      }));
    } catch (error) {
      console.error("Error fetching portfolio output:", error);
      setOutputTitles((prevTitles) => ({
        ...prevTitles,
        [id]: "Error fetching output",
      }));
    }
  };

  // Fetch the effect title for a specific sync item
  const fetchEffectTitle = async (id, portfolioID, syncItem) => {
    try {
      const title = await PortfolioEffects(portfolioID, syncItem);
      setEffectTitles((prevTitles) => ({
        ...prevTitles,
        [id]: title || "Kan titel niet ophalen",
      }));
    } catch (error) {
      console.error("Error fetching portfolio effect:", error);
      setEffectTitles((prevTitles) => ({
        ...prevTitles,
        [id]: "Error fetching effect",
      }));
    }
  };

  // Fetch titles whenever syncs change
  useEffect(() => {
    if (syncs) {
      syncs.forEach((sync) => {
        if (sync.Type === "output" && !outputTitles[sync.ID]) {
          fetchOutputTitle(sync.ID, sync.PortfolioID, sync.SyncItem);
        } else if (sync.Type === "effect" && !effectTitles[sync.ID]) {
          fetchEffectTitle(sync.ID, sync.PortfolioID, sync.SyncItem);
        }
      });
    }
  }, [syncs]);

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header" >
                <NotificationsNoneOutlinedIcon />
                <h1>Notificaties</h1>
            </div>
            <div id='notifications-container'>
              {syncs && syncs.map((sync) => (
                <div className='notification-item-container'>
                  <h2><PortfolioMeta portfolioID={sync.PortfolioID} syncs={syncs}/></h2>
                  <p>wil zijn {sync.Type}</p>
                  {sync.Type === 'output' && <p><b>{outputTitles[sync.ID] || "Loading..."}</b></p>}
                  {sync.Type === 'effect' && <p><b>{effectTitles[sync.ID] || "Loading..."}</b></p>}
                 
                  <p>synchroniseren</p>
                  <button onClick={() => history.push(`/${client}/synchronisationbuilder/${sync.ID}`)}>Verzoek behandelen</button>
                </div>
              ))}
            </div>
        </div>
    </div>
  )
}

export default Notifications
import {
  useFirestore,
  useFirestoreOrderBy,
  useFirestoreArrayContainsOrderBy
} from "../../firebase/useFirestore";
import ScrollToTop from "../../hooks/ScrollToTop";
import { useEffect, useState, useContext } from "react";
import useSettings from "../../hooks/Settings";
import IndicatorData from "../../components/Indicators/IndicatorData";
import { db } from "../../firebase/config";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { DashboardSystemMessage } from "../../hooks/impactAI/Prompts"
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import Tooltip from "../../components/common/Tooltip";
import DashboardOutputResultsDetail from "../../components/dashboard/DashboardOutputResultsDetail";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import UpdateOutputColors from "../../components/dashboard/UpdateOutputColors";
import OutputData from "../../components/dashboard/OutputData";
import Modal from 'react-modal';
import firebase from 'firebase'

const Dashboard = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen],
      hightlighted: [hightlighted, setHightlighted],
      hightlightText: [hightlightText, setHightlightText],
  } = useContext(ImpactAI);

  // State
  const [activityID, setActivityID] = useState(null);
  const [EffectID, setEffectID] = useState(null);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [dashboardSettingsDocid, setDashboardSettingsDocid] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [compagnyTimestamp, setCompagnyTimestamp] = useState(null);
  const [effectsWithIndicators, setEffectsWithIndicators] = useState([]);
  const [outputDetails, setOutputDetails] = useState(false)
  const [graphType, setGraphType] = useState('line')
  const [modalOpen, setModalOpen] = useState(false)

  // Hooks
  ScrollToTop();
  const secundairyColor = useSettings().SecundairyColor;
  const trailDays = useSettings().trailDays;
  const subscribtion = useSettings().subscribtion;
  Modal.setAppElement('#root');
  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  // Firestore
  const effects = useFirestore("OutputEffects");
  const outputs = useFirestore("Outputs");
  const activities = useFirestoreOrderBy("Activities", "Position", "asc");
  const questionnaireFields = useFirestoreArrayContainsOrderBy("QuestionnaireFields", "EffectId", EffectID ? EffectID : "123", "Position", "asc");
  const indicatorOutputs = useFirestoreArrayContainsOrderBy("Outputs", "EffectId", EffectID ? EffectID : "123", "Position", "asc");
  const dashboardSettings = useFirestore("DashboardSettings");
  const compagny = useFirestore("CompagnyMeta");

  //ImpactAI
  const pageSystemMessage = DashboardSystemMessage()
  const pageStartMessage = `Welkom bij het dashboard, ${auth?.ForName}. Hier kun je je impactresultaten inzien.
  Heb je vragen over het dashboard?`
  const pageExampleMessages = ['Wat zijn outputresultaten?', 'Wat zijn KPI"s?']
  const pageType = 'dashboard'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])

 // Reset hightlights
 useEffect(() => {
  setHightlighted(false)
  setHightlightText('')
},[])

// Test function
const testFunction = async () => {

  const sendEffectData = firebase.functions().httpsCallable('sendEffectData');
  const dataToSend = {
    effectId: 'c89f1380-6bd9-40ad-8058-d3722bd7ba57',
    indicatorQuestion: 'Heb je eigenwaarde?'
  };
  try {
    const result = await sendEffectData({ data: dataToSend });

    console.log(result.data)

    return result.data; // Return the data to be collected
  } catch (error) {
    console.error(error);
    return null; // Return null or appropriate error handling
  }
}

useEffect(() => {

testFunction()

},[])

  // Get the effects with indicators
  useEffect(() => {
    effects &&
      effects.map((item) => {
        db.collection("QuestionnaireFields")
          .where("EffectId", "array-contains", item.ID)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              const object = {
                ID: item.ID,
                Effect: item.Effect,
              };
              setEffectsWithIndicators((prevState) => [...prevState, object]);
            }
          });
      });
  }, [effects]);

  // Format date
  function formatDate(date) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  // Set de compagny timestamp (createdAt)
  useEffect(() => {
    compagny &&
      compagny.forEach((item) => {
        setCompagnyTimestamp(item.Timestamp);
      });
  }, [compagny]);

  // Set the start date and end date
  useEffect(() => {
    dashboardSettings &&
      dashboardSettings.map(
        (item) => (
          setStartDate(item.Start?.toDate()),
          setEndDate(item.End?.toDate()),
          setDashboardSettingsDocid(item.docid)
        )
      );
  }, [dashboardSettings]);

  // Set the first activity as default
  useEffect(() => {
    activities.length > 0
      ? setActivityID(activities[0].ID)
      : setActivityID(null);
  }, [activities]);

  // Set the first effect as default
  useEffect(() => {
    effectsWithIndicators.length > 0 ? setEffectID(effectsWithIndicators[0].ID) : setEffectID(null);
  }, [effectsWithIndicators]);

  // Update the selected activity
  const selecteActivity = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;

    setActivityID(id);
  };

  // Update the selected effect
  const selectedEffect = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;

    setEffectID(id);
  };

  // Update the selected period
  const displayRangePicker = () => {
    setShowRangePicker(!showRangePicker);
  };

  const startDateHandler = (e) => {
    const date = new Date(e.target.value);

    setSelectedStartDate(date);
  };

  const endDateHandler = (e) => {
    const date = new Date(e.target.value);

    setSelectedEndDate(date);
  };

  const saveStartDate = () => {
    db.collection("DashboardSettings")
      .doc(dashboardSettingsDocid)
      .update({
        Start: selectedStartDate,
      })
      .then(() => {
        window.location.reload();
      });
  };

  const saveEndDate = () => {
    db.collection("DashboardSettings")
      .doc(dashboardSettingsDocid)
      .update({
        End: selectedEndDate,
      })
      .then(() => {
        window.location.reload();
      });
  };

  const resetDateRangeHandler = () => {
    db.collection("DashboardSettings")
      .doc(dashboardSettingsDocid)
      .update({
        End: new Date(),
        Start: new Date("2000-01-01T12:00:00"),
      })
      .then(() => {
        window.location.reload();
      });
  };

  // Update the graph type
  const activityGraphTypeHandler = (e) => {
    const type = e.target.dataset.type;
    const docid = e.target.dataset.docid;

    console.log(type, docid);

    db.collection("Activities").doc(docid).update({
      GraphType: type,
    });
  };


  // Style of output details container
  const detailContainerStyle = {
    maxHeight: outputDetails ? '5000px' : '0px',
    overflow: 'hidden',
    transition: 'max-height 2s ease-in-out',
  };


  return (
    <div className="main">
      <div
        className="main-container"
        style={{
          display:
            trailDays === 0 && subscribtion === "trial" ? "none" : "flex",
        }}
      >
         <div className="page-header">
          <DashboardOutlinedIcon/>
          <h1>Dashboard</h1>
        </div>
        <div className="period-select-container">
          <div className="period-select">
            <div id="selected-range-container">
              {startDate ? (
                <p>{startDate && formatDate(startDate)}</p>
              ) : (
                <p>Start</p>
              )}
              <p>-</p>
              {endDate ? <p>{endDate && formatDate(endDate)}</p> : <p>Eind</p>}
              <CalendarTodayOutlinedIcon
                id="home-range-picker-icon"
                onClick={displayRangePicker}
              />
              <button className="button-simple" onClick={resetDateRangeHandler} style={{color: secundairyColor}}>
                Reset
              </button>
            </div>
            <div
              id="range-picker-container"
              style={{ display: showRangePicker ? "block" : "none" }}
            >
              <div
                onClick={displayRangePicker}
                className="close-modal-icon-container"
              >
                <p>x</p>
              </div>

              <div className="range-picker-input-container">
                <p>
                  <b>Start</b>
                </p>
                <input
                  type="date"
                  defaultValue={
                    startDate ? startDate?.toISOString().split("T")[0] : null
                  }
                  onChange={startDateHandler}
                />
                <button className="button-simple" onClick={saveStartDate}>
                  Opslaan
                </button>
              </div>
              <div className="range-picker-input-container">
                <p>
                  <b>Eind</b>
                </p>
                <input
                  type="date"
                  defaultValue={
                    endDate ? endDate?.toISOString().split("T")[0] : null
                  }
                  onChange={endDateHandler}
                />
                <button className="button-simple" onClick={saveEndDate}>
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <BarChartOutlinedIcon className='icon'/>
            <h2>Outputs</h2>
          </div>
          <div className="select-activity-container">
            <div className="select-activity-inner-container">
              {activities &&
                activities.map((item) => (
                  <div
                    key={item.ID}
                    className="activity-select-item-container"
                    onClick={selecteActivity}
                    data-id={item.ID}
                    style={{
                      backgroundColor:
                        activityID === item.ID ? secundairyColor : "white",
                    }}
                  >
                    <p
                      data-id={item.ID}
                      onClick={selecteActivity}
                      style={{
                        color: activityID === item.ID ? "white" : "#616161",
                      }}
                    >
                      {item.Activity}
                    </p>
                  </div>
                ))}
            </div>
          </div>

          <div className="home-outputs-container">
            <div id='dashboard-graph-options-container'>

                {/* Zoom in and out */}
            {!outputDetails &&
                <div onClick={() => setOutputDetails(true)}>
                  <Tooltip content='Bekijk details' top='40px'>
                    <ZoomInOutlinedIcon />
                  </Tooltip>
                </div>
              }
              {outputDetails &&
                <div onClick={() => setOutputDetails(false)}>
                  <Tooltip content='Verberd details' top='40px'>
                    <ZoomOutOutlinedIcon />
                  </Tooltip>
                </div>
              }

              {/* Change graphtype */}
              {graphType === 'bar' &&
                <div onClick={() => setGraphType('line')}>
                  <Tooltip content='Cummulatief' top='40px'>
                    <TimelineOutlinedIcon style={{color: graphType === 'line' ? secundairyColor : 'black'}}/>
                  </Tooltip>
                </div>
              }
              {graphType === 'line' &&
                <div onClick={() => setGraphType('bar')}>
                  <Tooltip content='Momentopname' top='40px'>
                    <EqualizerOutlinedIcon style={{color: graphType === 'bar' ? secundairyColor : 'black'}}/>
                  </Tooltip>
                </div>
              }
             
             {/* Change output colors */}
              <div onClick={() => setModalOpen(!modalOpen)}>
                <Tooltip content='Kleuren' top='40px'>
                  <PaletteOutlinedIcon />
                </Tooltip>
              </div>
            </div>
            <OutputData activity={activityID} startDate={startDate} endDate={endDate} graphType={graphType}/>
            <div id='dashboard-output-detail-container' style={detailContainerStyle}>
              {outputs && outputs.map(item => (
                <>
                {item.ActivityID === activityID &&
                  <DashboardOutputResultsDetail 
                  outputID={item.ID}
                  startDate={startDate}
                  endDate={endDate}
                  color={item.Color} 
                  graphType={graphType}
                  />
                  }
                </>
              ))}
            </div>
          </div>
        </div>

        <div className='home-section-container'>
            <div className='title-edit-container'>
                <TrackChangesOutlinedIcon className='icon'/>
                <h2>Effecten</h2>
            </div>
            <div className="select-activity-container">
                <div className="select-activity-inner-container">
                    {effectsWithIndicators && effectsWithIndicators.map(item => (
                        <div key={item.ID} data-id={item.ID} className='activity-select-item-container' onClick={selectedEffect} style={{backgroundColor: EffectID === item.ID ? secundairyColor : 'white'}}>
                            <p data-id={item.ID} onClick={selectedEffect} style={{color: EffectID === item.ID ? 'white' : '#616161'}}>{item.Effect}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {questionnaireFields && questionnaireFields.map(item => (
                    <div key={item.ID} className='home-kpi-container'>
                        <IndicatorData indicator={item} startDate={startDate} endDate={endDate} graphType={item.GraphType}/>
                    </div>
                ))}
            </div>
            <div>

                {indicatorOutputs && indicatorOutputs.map(item => (
                  <div key={item.ID} className='home-kpi-container'>
                        <IndicatorData indicator={item} startDate={startDate} endDate={endDate} graphType={item.GraphType}/>
                    </div>
                ))}
            </div>
          </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={modalOpen}
        style={modalStyles}
        contentLabel="Color settings outputgraph"
        >
            <div>
                <h1>Wijzig outputkleuren</h1>
                <UpdateOutputColors activityID={activityID}/>
            </div>
            <div id='modal-button-container'>
                <button id='modal-cancel-button' onClick={() => setModalOpen(false)}>Annuleren</button>
                <button id='modal-save-button' onClick={() => setModalOpen(false)} >Opslaan</button>
            </div>
        </Modal>
    </div>                
  );
};

export default Dashboard;

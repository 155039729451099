import { BarChart, Bar, Rectangle, LineChart, Line, XAxis, YAxis, Area, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import spinner from '../../images/spinner-ripple.svg'
import React from 'react'

const OutputMultiLineGraph = ({data, isLoading, outputs, graphType}) => {

  return (
    <>
      {isLoading ? (
        <div id="graph-loading-container">
          <img src={spinner} alt="spinner" className="spinner" />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          {graphType === 'bar' ? (
            <BarChart
              width="100%"
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {outputs &&
                outputs.map((output, index) => (
                  <Bar
                    key={`bar-${index}`}
                    dataKey={output.Title}
                    fill={output.Color}
                    activeBar={<Rectangle fill={output.Color} stroke={output.Color} />}
                  />
                ))}
            </BarChart>
          ) : (
            <LineChart
              width="100%"
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >

              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {outputs &&
                outputs.map((output, index) => (
                  <React.Fragment key={`line-${index}`}>
                    <Line
                      type="monotone"
                      dataKey={output.Title}
                      stroke={output.Color}
                      strokeWidth="3"
                    />
                   <Area
                      type="monotone"
                      dataKey={output.Title}
                      fill={'#8884d8'}
                      fillOpacity={1}
                    />
                  </React.Fragment>
                ))}
            </LineChart>
          )}
        </ResponsiveContainer>
      )}
    </>
  );
}

export default OutputMultiLineGraph
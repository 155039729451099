import { client } from "../../hooks/Client"
import { useFirestoreGeneralOrderBy, useFirestore} from "../../firebase/useFirestore"
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { db } from "../../firebase/config";
import useSettings from "../../hooks/Settings";
import NextStepContainer from "../../components/common/NextStepContainer.jsx";
import { OutputsSystemMessage, OutputsToolCallContent, OutputsSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { useContext, useState, useEffect } from "react";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import { v4 as uuid } from 'uuid';
import { timestamp } from "../../firebase/config";
import RandomColorGenerator from "../../hooks/RandomColorGenerator";
import { ImpactAI } from '../../StateManagment/ImpactAI';
import Breadcrumb from "../../components/common/Breadcrumb";
import OutputIsSyncedTitle from "../../components/syncronisations/OutputIsSyncedTitle";
import OutputIsSyncedDelete from "../../components/syncronisations/OutputIsSyncedDelete";
import PageHeader from "../../components/layout/PageHeader.jsx";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import SavedNotice from "../../components/common/SavedNotice"

const OutputSettings = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // States
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [activityID, setActivityID] = useState('')
    
    // Hooks
    const history = useHistory()
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const randomColor = RandomColorGenerator()

    // Firestore
    const outputs = useFirestoreGeneralOrderBy('Outputs', 'ActivityID', activityID, 'Position', 'asc')
    const activities = useFirestore('Activities')

    console.log(outputs)

    //ImpactAI
    const pageSystemMessage = OutputsSystemMessage()
    const pageToolCallContent = OutputsToolCallContent()
    const pageSaveMessage = OutputsSaveMessage(activities.length)
    const pageStartMessage = `Hoi, ${auth?.ForName}. In deze stap gaan we de kwantitatieve impact (outputs) benoemen die je gaat bijhouden voor je activiteiten. 
    Mag ik een voorstel doen?`
    const pageExampleMessages = []
    const pageCollection = 'Outputs'
    const pageField = 'Title'
    const pageParentId = ''
    const pageType = 'output'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth, activities])

    // Set first activity as default
    useEffect(() => {
        activities && setActivityID(activities[0] ? activities[0].ID : '')
    },[activities])

    // Add output
    const addOutput = (e) => {

        db.collection('Outputs')
        .doc()
        .set({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Title: '',
            Position: outputs.length + 1,
            Color: randomColor,
            ActivityID: activityID
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Output count indicator
    const ouputCountIndicator = (activityID) => {
        const outputCount = outputs.filter(output => output.ActivityID === activityID).length
        return outputCount
    }

  return (
    <div className="main">
        <div className="main-container" >
            <PageHeader 
                heading={'Outputs'}
                description={`In deze stap ga je de kwantitatieve impact (outputs) benoemen die je gaat bijhouden voor je activiteiten.`}
                AI={true}
            />
            <div className="select-activity-container select-activity-container-wizard">
                <div className="select-activity-inner-container">
                {activities && activities.map(item => (
                        <div key={item.ID} data-id={item.ID} data-title={item.Activity} className='activity-select-item-container' onClick={() => setActivityID(item.ID)} style={{backgroundColor: activityID === item.ID ? secundairyColor : 'white'}}>
                            <p data-id={item.ID} data-title={item.Activity} onClick={() => setActivityID(item.ID)} style={{color:  activityID === item.ID ? 'white' : '#616161'}}>{item.Activity}</p>
                            {/* <p>{ouputCountIndicator(item.ID)}</p> */}
                        </div>
                    ))}
                </div>
            </div>
            <div className='table-container dashboard-container'>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                        {/* <th style={{backgroundColor: secundairyColor}}>ACTIVITEIT</th> */}
                        {/* <th style={{backgroundColor: secundairyColor}}>KLEUR (IN GRAFIEKEN)</th> */}
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {outputs && outputs.map((output, index) => (
                            <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={output}
                            input={
                                <OutputIsSyncedTitle outputID={output.ID}/>
                                }
                            parentIndex={index}
                            collection={'Outputs'}
                            itemArray={outputs}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={output.Title}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                            <td>
                                <OutputIsSyncedDelete outputID={output.ID}/>
                            </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <AddItemTableRow toolTipContent={'Output toevoegen'} onClick={addOutput}/>
            </div>
            <div className="impactstrategy-saved-and-nextstep-container">
                <SavedNotice />
                <NextStepContainer url={'effects'} mainItem={'impactstrategy'}/>
            </div>
            <Breadcrumb
            section={'Impactstrategie'}
            previousPage={'Activiteiten'}
            previousPageUrl={'Activities'}
            nextPage={'Effecten'}
            nextPageUrl={'effects'}
            counter={6}
            totalSteps={8}
            />
        </div>
    </div>
  )
}

export default OutputSettings